<template>
  <section>
    <ButtonGroup>
      <Button icon="ios-cube" shape="circle" :size="size" @click="show = true">{{ title }}</Button>
    </ButtonGroup>
    <Modal v-model="show" :title="title">
      <Input ref="keywords" v-model="keywords" @on-enter="search" placeholder="请输入关键字">
        <Select slot="prepend" style="width: 80px" v-model="search_key">
          <Option value="question_name">题目</Option>
          <Option value="id">题目ID</Option>
        </Select>
        <Button slot="append" icon="ios-search" @click="search"></Button>
      </Input>

      <Table class="mt" border :columns="columns" :data="data"></Table>

      <Page
        class="mt"
        show-sizer
        :current="page"
        :page-size="limit"
        :page-size-opts="[ 10, 15, 20 ]"
        :total="total"
        @on-change="changePage"
        @on-page-size-change="changePageSize"
      />
    </Modal>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "选择题目"
    },
    questionType: {
      type: Number,
      default: 0
    },
    questionSubjectId: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: "default"
    },
    autoClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      keywords: "",
      search_key: "question_name",
      page: 1,
      limit: 10,
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          align: "center",
          minWidth: 80
        },
        {
          title: "题目类型",
          key: "question_type",
          align: "center",
          minWidth: 100,
          render: (h, params) => {
            switch (params.row.question_type) {
              case 1:
                return h("Tag", { props: { color: "orange" } }, "单选题");

              case 2:
                return h("Tag", { props: { color: "green" } }, "多选题");

              case 3:
                return h("Tag", { props: { color: "cyan" } }, "填空题");

              case 4:
                return h("Tag", { props: { color: "gold" } }, "判断题");

              case 5:
                return h("Tag", { props: { color: "blue" } }, "论述题");

              case 6:
                return h("Tag", { props: { color: "purple" } }, "阅读理解");

              default:
                return h("Tag", "-");
            }
          }
        },
        {
          title: "题目",
          key: "question_name",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            if (params.row.question_name_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.question_name_url
                      ? params.row.question_name_url
                      : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.question_name.substr(0, 10));
            }
          }
        },
        {
          title: "选择",
          width: 80,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("Button", {
                props: {
                  type: "primary",
                  size: "small",
                  icon: "md-share-alt"
                },
                on: {
                  click: () => {
                    this.$emit("on-select", params.row);
                    if (this.autoClose) this.show = false;
                  }
                }
              })
            ]);
          }
        }
      ]
    };
  },
  methods: {
    loadData() {
      var params = {
        question_type: this.questionType,
        question_subject_id: this.questionSubjectId,
        page: this.page,
        limit: this.limit,
        keywords: this.keywords,
        search_key: this.search_key
      };

      this.$http.get("/questionBank/list", { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
    search() {
      this.page = 1;
      this.data = [];
      this.loadData();
    },
    changePage(page) {
      this.page = page;
      this.loadData();
    },
    changePageSize(limit) {
      this.page = 1;
      this.limit = limit;
      this.loadData();
    }
  },
  watch: {
    show(value) {
      if (value) {
        this.loadData();
      }
    }
  }
};
</script>